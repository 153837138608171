<template>
  <div
    @keyup.esc="closeModal()"
    class="modal active"
    :class="{
      'modal-fullscreen': (($breakpoint.xs && allowFullscreen) || forceFullscreenAnySize) && !mobileAlignBottom,
      'modal-align-bottom': $breakpoint.xs && mobileAlignBottom
    }"
    :id="name"
  >
    <a class="modal-overlay" aria-label="Close" v-on="overlayClose ? { click: () => closeModal() } : {}" />

    <div class="modal-container-wrapper shadow-2xl sm:rounded" :class="modalContainerClass">
      <div class="modal-container sm:rounded">
        <slot name="floatingElement" />

        <slot v-if="customLayout" :closeModal="closeModal"></slot>

        <template v-else>
          <slot v-if="showHeader" name="header">
            <div
              class="header bg-gray-700 text-white sm:rounded-t"
              :class="{ 'rounded-t': mobileAlignBottom, 'border-b border-gray-lighter': headerBorderBottom }"
            >
              <div class="modal-header flex flex-wrap px-5 py-1 items-center justify-between">
                <slot name="headerContent">
                  <h4 class="text-lg sm:text-xl font-medium mb-0 capitalize">{{ title }}</h4>

                  <div class="flex items-center">
                    <UtilButton
                      v-if="showHelp"
                      @click="openSupportModal()"
                      color="white"
                      textLink
                      class="mr-2 leading-none"
                    >
                      <FontAwesomeIcon :icon="faQuestionCircle" />
                      <span>Help</span>
                    </UtilButton>

                    <a
                      v-if="allowClose"
                      aria-label="Close"
                      @click="closeModal()"
                      class="btn btn-link btn-icon-only text-white hover:text-gray-lighter text-lg px-4 -mr-4 leading-none cursor-pointer"
                    >
                      <FontAwesomeIcon :icon="faTimes" />
                    </a>
                  </div>
                </slot>
              </div>

              <div v-if="$slots.subheader">
                <slot name="subheader"></slot>
              </div>
            </div>
          </slot>

          <div
            class="modal-body relative"
            name="modal-body"
            :class="{
              'px-5 modal-body-padding': bodyPadding,
              'no-scroll': disableBodyScroll,
              'rounded-b': !$scopedSlots.footer && !$scopedSlots.subfooter
            }"
          >
            <slot name="body" :closeModal="closeModal"></slot>
          </div>

          <div v-if="$scopedSlots.subfooter">
            <slot name="subfooter"></slot>
          </div>

          <div
            v-if="$scopedSlots.footer"
            class="modal-footer rounded-b"
            :class="[{ 'py-4 px-5': footerPadding }, footerClass]"
          >
            <slot name="footer" :closeModal="closeModal"></slot>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import UtilButton from '@/components/utils/UtilButton'
import { faTimes, faQuestionCircle } from '@fortawesome/pro-regular-svg-icons'

export default {
  name: 'ModalBase',
  components: {
    UtilButton
  },
  props: {
    name: {
      type: String,
      required: true
    },
    title: String,
    customLayout: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    bodyPadding: {
      type: Boolean,
      default: true
    },
    footerPadding: {
      type: Boolean,
      default: true
    },
    footerClass: {
      type: String,
      default: 'bg-background'
    },
    allowClose: {
      type: Boolean,
      default: true
    },
    allowFullscreen: {
      type: Boolean,
      default: true
    },
    overlayClose: Boolean,
    modalContainerClass: {
      type: String,
      default: 'max-w-xl bg-white'
    },
    headerBorderBottom: {
      type: Boolean,
      default: true
    },
    showHelp: Boolean,
    disableBodyScroll: Boolean,
    forceFullscreenAnySize: Boolean,
    mobileAlignBottom: Boolean
  },
  data() {
    return {
      faTimes,
      faQuestionCircle
    }
  },
  methods: {
    async closeModal(force = false) {
      if (!this.allowClose && !force) {
        return false
      }

      this.$emit('close')

      // Give close handlers a chance to fire
      await this.$nextTick()

      this.$modal.close(this.name)
    },

    openSupportModal() {
      this.$modal.open('ModalContactSupport')
    }
  }
}
</script>

<style lang="scss" scoped>
#app.ios .modal {
  padding-top: constant(safe-area-inset-top); /* Status bar height on iOS 11.0 */
  padding-top: env(safe-area-inset-top); /* Status bar height on iOS 11+ */
}

.modal {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
  display: none;
  justify-content: center;
  align-items: center;
  opacity: 0;
  overflow: hidden;
  @apply p-2;

  &:target,
  &.active {
    display: flex;
    opacity: 1;
    @apply z-40;

    .modal-overlay {
      @apply bg-background opacity-75;
      bottom: 0;
      cursor: default;
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
    }

    .modal-container {
      animation: slide-down 0.4s ease 1;
      z-index: 1;
    }
  }
}

// This extra wrapper is required to fix IE 11 flex height overflow bug
.modal-container-wrapper {
  display: flex;
  flex-direction: row;
  @apply w-full relative;
}

.modal-container {
  @apply shadow-2xl rounded relative;
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  width: 100%;
  min-height: 0;

  .modal-body {
    overflow-y: auto;
    position: relative;
    flex: 1 1 auto;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translate3d(0, 0, 0);
    height: calc(100% + 1px);
  }

  .modal-body.no-scroll {
    overflow: hidden;
  }

  .modal-footer {
    @apply border-t border-gray-lighter relative;
  }
}

#app.xs .modal-header {
  padding-top: 2px;
  padding-bottom: 2px;
}

#app.ios .modal-header {
  padding-top: 0px;
}

.modal-body-padding {
  @apply py-4;
}

.modal.modal-fullscreen {
  padding: 0px;

  & .modal-container,
  & .modal-container-wrapper {
    border-radius: 0 !important;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    box-shadow: none;
  }

  & .header {
    border-radius: 0 !important;
  }
}

.modal.modal-align-bottom {
  @apply flex-col justify-end;

  & .modal-container,
  & .modal-container-wrapper {
    @apply shadow-2xl;
    border-radius: 0.5rem !important;
    height: auto;
  }

  & .modal-footer {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  & .header {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
}
</style>
