var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal active",class:{
    'modal-fullscreen': ((_vm.$breakpoint.xs && _vm.allowFullscreen) || _vm.forceFullscreenAnySize) && !_vm.mobileAlignBottom,
    'modal-align-bottom': _vm.$breakpoint.xs && _vm.mobileAlignBottom
  },attrs:{"id":_vm.name},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeModal()}}},[_c('a',_vm._g({staticClass:"modal-overlay",attrs:{"aria-label":"Close"}},_vm.overlayClose ? { click: function () { return _vm.closeModal(); } } : {})),_vm._v(" "),_c('div',{staticClass:"modal-container-wrapper shadow-2xl sm:rounded",class:_vm.modalContainerClass},[_c('div',{staticClass:"modal-container sm:rounded"},[_vm._t("floatingElement"),_vm._v(" "),(_vm.customLayout)?_vm._t("default",null,{"closeModal":_vm.closeModal}):[(_vm.showHeader)?_vm._t("header",[_c('div',{staticClass:"header bg-gray-700 text-white sm:rounded-t",class:{ 'rounded-t': _vm.mobileAlignBottom, 'border-b border-gray-lighter': _vm.headerBorderBottom }},[_c('div',{staticClass:"modal-header flex flex-wrap px-5 py-1 items-center justify-between"},[_vm._t("headerContent",[_c('h4',{staticClass:"text-lg sm:text-xl font-medium mb-0 capitalize"},[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),_c('div',{staticClass:"flex items-center"},[(_vm.showHelp)?_c('UtilButton',{staticClass:"mr-2 leading-none",attrs:{"color":"white","textLink":""},on:{"click":function($event){return _vm.openSupportModal()}}},[_c('FontAwesomeIcon',{attrs:{"icon":_vm.faQuestionCircle}}),_vm._v(" "),_c('span',[_vm._v("Help")])],1):_vm._e(),_vm._v(" "),(_vm.allowClose)?_c('a',{staticClass:"btn btn-link btn-icon-only text-white hover:text-gray-lighter text-lg px-4 -mr-4 leading-none cursor-pointer",attrs:{"aria-label":"Close"},on:{"click":function($event){return _vm.closeModal()}}},[_c('FontAwesomeIcon',{attrs:{"icon":_vm.faTimes}})],1):_vm._e()],1)])],2),_vm._v(" "),(_vm.$slots.subheader)?_c('div',[_vm._t("subheader")],2):_vm._e()])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"modal-body relative",class:{
            'px-5 modal-body-padding': _vm.bodyPadding,
            'no-scroll': _vm.disableBodyScroll,
            'rounded-b': !_vm.$scopedSlots.footer && !_vm.$scopedSlots.subfooter
          },attrs:{"name":"modal-body"}},[_vm._t("body",null,{"closeModal":_vm.closeModal})],2),_vm._v(" "),(_vm.$scopedSlots.subfooter)?_c('div',[_vm._t("subfooter")],2):_vm._e(),_vm._v(" "),(_vm.$scopedSlots.footer)?_c('div',{staticClass:"modal-footer rounded-b",class:[{ 'py-4 px-5': _vm.footerPadding }, _vm.footerClass]},[_vm._t("footer",null,{"closeModal":_vm.closeModal})],2):_vm._e()]],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }