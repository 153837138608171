<template>
  <div>
    <img v-if="compHaulageMapImageUrl" :src="compHaulageMapImageUrl" alt="Haulage Route" class="block w-full" />

    <div :class="{ 'px-4': bleedMapImage }">
      <div class="flex items-center mt-4">
        <div class="w-5/12 font-medium">System Quote</div>

        <div class="flex-grow pl-4 flex justify-between items-center">
          <UtilCurrency :value="haul.estimate.price" class="font-mono font-bold leading-snug" />

          <UtilButton @click="areCalculationsVisible = !areCalculationsVisible" textLink class="text-sm text-gray">
            <span>calculations</span>
            <FontAwesomeIcon :icon="areCalculationsVisible ? faChevronUp : faChevronDown" class="text-xs" />
          </UtilButton>
        </div>
      </div>

      <div v-if="areCalculationsVisible" class="mt-4">
        <div class="font-medium">Quote Calculations</div>

        <div
          v-for="(vehicleCalculations, index) in haul.estimate.calculations"
          :key="index"
          class="mt-4 border-t border-b border-gray py-2 px-2"
        >
          <div v-for="(value, key) in vehicleCalculations" :key="key">{{ key }}: {{ value }}</div>
        </div>
      </div>

      <div class="flex items-center mt-4">
        <div class="w-5/12 font-medium">Date Booked</div>

        <div class="flex-grow pl-4">{{ $date.relative(haul.createdAt) }}</div>
      </div>

      <div class="flex items-center mt-4">
        <div class="w-5/12 font-medium">Requested Date Of Haul</div>

        <div class="flex-grow pl-4">{{ compHaulInNextDays }}</div>
      </div>

      <div class="flex items-center mt-4">
        <div class="w-5/12 font-medium">Mileage</div>

        <div class="flex-grow pl-4">{{ haul.estimate.request.distance }} miles</div>
      </div>

      <div class="flex items-center mt-4">
        <div class="w-5/12 font-medium">Load Type</div>

        <div class="flex-grow pl-4">{{ haul.product.title }}</div>
      </div>

      <div class="flex items-center mt-4">
        <div class="w-5/12 font-medium">Quantity</div>

        <div class="flex-grow pl-4">{{ haul.quantity }}</div>
      </div>

      <div class="flex items-center mt-4">
        <div class="w-5/12 font-medium">Average Weight Per Head</div>

        <div class="flex-grow pl-4">{{ haul.weight }}kg</div>
      </div>

      <div class="flex items-center mt-4">
        <div class="w-5/12 font-medium">Include Weighbridge</div>

        <div class="flex-grow pl-4">
          {{ haul.estimate.request.points[1].flags.weighbridge ? 'Yes' : 'No' }}
        </div>
      </div>

      <div class="flex items-center mt-4">
        <div class="w-5/12 font-medium">Include Bedding</div>

        <div class="flex-grow pl-4">{{ haul.estimate.request.points[1].flags.bedding ? 'Yes' : 'No' }}</div>
      </div>

      <template v-if="haul.notes">
        <div class="font-medium mt-4">Note From User</div>

        <p class="italic">{{ haul.notes }}</p>
      </template>

      <div class="flex border border-gray-lighter rounded px-2 py-2 mt-4">
        <div class="w-6/12 pr-2">
          <h4 class="text-lg font-medium">Seller</h4>

          <p class="mt-2 font-medium">{{ get(haul.points, '[0].name', 'Unknown Name') }}</p>

          <p class="font-mono mt-1">{{ haul.points[0].contact }}</p>
          <p class="">{{ get(haul.points, '[0].email', 'Unknown Email') }}</p>

          <p class="text-gray uppercase font-normal text-sm mt-2 mb-1">Leaving From</p>

          <p>{{ haul.points[0].geo.building }}</p>
          <p>{{ haul.points[0].geo.line1 }}</p>
          <p v-if="haul.points[0].geo.line2">{{ haul.points[0].geo.line2 }}</p>
          <p>{{ haul.points[0].geo.locality }}</p>
          <p>{{ haul.points[0].geo.region }}</p>
          <p>{{ haul.points[0].geo.country }}</p>
        </div>

        <div class="w-6/12 pl-2">
          <h4 class="text-lg font-medium">Buyer</h4>

          <p class="mt-2 font-medium">Unknown name</p>

          <p class="font-mono mt-1">{{ haul.points[1].contact }}</p>
          <p class="">Unknown email</p>

          <p class="text-gray uppercase font-normal text-sm mt-2 mb-1">Going To</p>

          <p>{{ haul.points[1].geo.building }}</p>
          <p>{{ haul.points[1].geo.line1 }}</p>
          <p v-if="haul.points[1].geo.line2">{{ haul.points[1].geo.line2 }}</p>
          <p>{{ haul.points[1].geo.locality }}</p>
          <p>{{ haul.points[1].geo.region }}</p>
          <p>{{ haul.points[1].geo.country }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons'
import { get } from 'lodash'
import UtilCurrency from '@/components/utils/UtilCurrency.vue'
import UtilButton from '@/components/utils/UtilButton.vue'

export default {
  name: 'UtilHaulageDetails',
  components: {
    UtilCurrency,
    UtilButton
  },
  props: {
    haul: {
      type: Object,
      required: true
    },
    bleedMapImage: Boolean
  },
  data() {
    return {
      areCalculationsVisible: false,
      get,
      faChevronDown,
      faChevronUp
    }
  },
  computed: {
    compHaulageMapImageUrl() {
      let mapDataUrlParams = {
        apiKey: process.env.VUE_APP_HERE_MAPS_API_KEY,
        waypoint0: this.haul.points[0].geo.coordinates.slice().reverse().join(','),
        waypoint1: this.haul.points[1].geo.coordinates.slice().reverse().join(','),
        lc: '3182ce', // Line colour
        lw: 4, // Line width
        t: 0, // Map tile type (default daytime) https://developer.here.com/documentation/map-image/dev_guide/topics/resource-map.html
        w: 900,
        h: 190
      }

      return `https://image.maps.ls.hereapi.com/mia/1.6/routing?${new URLSearchParams(
        mapDataUrlParams
      ).toString()}`
    },

    compHaulInNextDays() {
      let daysDiff = this.$date(this.haul.points[0].date[1]).diff(this.$date(), 'days')

      if (daysDiff > 0) {
        return `Within ${daysDiff} days of now`
      } else if (daysDiff < 0) {
        return this.$date.relative(this.haul.points[0].date[1])
      } else {
        return 'ASAP'
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
